body.home {
    background-image: linear-gradient(to bottom, #060141, #040024 33%);

    &::before {
        background-image: url('../../../images/bg-elements-hp-desktop.svg');
        background-position: 70% -191px;
        background-repeat: no-repeat;
    }

    @media (max-width: 1365px) {
        background-image: linear-gradient(to bottom, #040024, #060141 22%);

        &::before {
            background-image: url('../../../images/bg-elements-hp-mobile.svg');
            background-position: 30% -78px;
        }
    }

    & .navbar {
        background-color: transparent;
        background-image: none;

        &.active{
            background-image: linear-gradient(97deg, #040024 5%, #060141);
        }
    }
}

.home .layout {
    min-height: 1746px;

    &>div:first-child {
        margin-top: 197px;

        @media (max-width: 1365px) {
            margin-top: 0;
        }
    }
}

.layout .home {
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1365px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__header {
        display: flex;
        flex-direction: column;
        margin-top: 183px;

        @media (max-width: 1365px) {
            margin-top: 108px;
            max-width: 560px;
            width: calc(100% - 92px);
        }

        &--title {
            font-size: 48px;
            font-weight: bold;
            line-height: 1.35;

            @media (max-width: 767px) {
                font-size: 28px;
            }

            span {
                &:first-child {
                    color: #fea716;
                }

                &:last-child {
                    display: block;
                }
            }
        }


        &--subtitle {
            font-size: 24px;
            font-weight: normal;
            line-height: 1.35;
            margin: 0;
            margin-top: 28px;

            @media (max-width: 767px) {
                font-size: 18px;
                margin-top: 16px;
            }
        }
    }

    &__widget {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        min-height: 576px;
        max-width: 560px;
        background: #191730;
        width: 100%;
        padding: 0 80px;
        box-sizing: border-box;
        max-height: 576px;
        @media (max-width: 1365px) {
            margin-top: 80px;
            padding-left: 16px;
            width: calc(100% - 48px);
            min-height: 524px;
        }
    }

    &__works {
        flex: 1;
        width: 100%;
        position: relative;
        top: 85px;
        z-index: 5;
    }
}