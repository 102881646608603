body.restricted_countries {
    &::before {
        background-image: url('../../../images/bg-elements-countries-desktop.svg');
        background-position: -29vw -110px;
    }

    & .layout {
        z-index: 2;
        min-height: auto;

        >section {
            margin-top: 108px;
            top: 109px;
            position: relative;
        }


        @media (max-width: 767px) {
            >section {
                top: 0;
            }
        }

    }

    @media (max-width: 1365px) {
        &::before {
            background-position: -93vw -110px;
        }
    }

    @media (max-width: 767px) {
        &::before {
            background-image: url('../../../images/bg-elements-countries-mobile.svg');
            background-position: -325px -35px;
        }
    }
}


section.restricted_countries {
    max-width: 800px;

    .card {
        width: 100%;
        margin-top: 32px;
        min-height: 590px;

        @media (max-width: 1365px) {
            padding: 24px 28px;
            padding-right: 0;
            // min-height: 424px;
        }

        @media (max-width: 767px) {
            border-radius: 15px;
            margin-top: 0;
            top: 48px;
            position: relative;
        }

        &__container {
            align-items: flex-start;
            justify-content: space-between;
            min-height: 566px;
            margin-top: 0;
        }
    }
}