.navbar {
    display: flex;
    align-items: center;
    min-height: 82px;
    width: 100%;
    position: fixed;
    background-color: #040024;
    background-image: linear-gradient(97deg, #040024 5%, #060141);
    z-index: 9999;
    top: 0;

    @media (max-width: 767px) {
        min-height: 56px;
    }

    &__container {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 24px;
        max-width: 1170px;
        margin: 0 auto;
        align-self: stretch;

        &--logo {
            flex: 0 0 35%;
        }

        &--links {
            flex: 0 0 55%;
            align-self: stretch;
            display: flex;
            align-items: center;

            a {
                font-size: 14px;
                line-height: 1.36;
                color: white;
                text-decoration: none;
                margin: 0 1rem;
                display: flex;
                align-items: center;
                align-self: stretch;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                box-sizing: border-box;

                &:active {
                    opacity: 0.9;
                }

                &.active {
                    border-bottom: 3px solid #fea716;
                }
            }
        }

        &--icons {
            text-align: right;
            flex: 0 0 10%;
        }
    }

    @media (max-width: 767px) {
        &__container {
            &--links {
                display: none;
            }

            &--icons {
                display: none;
            }

            &--menu {
                flex: 1;
                text-align: right;
                position: relative;


                &-checkbox {
                    position: absolute;
                    visibility: hidden;

                    &:checked~label span {
                        background-image: url('../../../images/icons/menu-icon-blue.svg');
                        transform: rotateZ(180deg);
                    }

                    &:checked~.menu_bar_mobile {
                        position: fixed;
                        top: 0;
                        left: 0;
                        background-color: #040024;
                        background-image: linear-gradient(to bottom, #040024, #060141 22%);
                        width: 100%;
                        height: 100vh;
                        transition: 0.5s height, 0s margin;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                        .menu_bar_mobile__logo{
                            height: 52px;
                            margin-bottom: 45px;
                            border: 0;
                        }

                        a {
                            

                            color: white;
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                            align-self: stretch;
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            box-sizing: border-box;
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 3.5;
                            border-bottom: 1px solid #27304199;
                            margin: 0;
                            padding-left: 24px;

                            &:active {
                                opacity: 0.9;
                            }

                            &.active {
                                color: #fea716;
                            }
                        }
                    }
                }

                & .menu_bar_mobile {
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: -9999px;
                }

                & label {
                    margin-left: auto;

                    &:hover {
                        cursor: pointer;
                    }
                }

                & label span {
                    background-image: url('../../../images/icons/menu-icon-white.svg');
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    transition: 0.3s all;
                }
            }
        }
    }
}