.card {
    width: 360px;
    height: 400px;
    box-shadow: 0 3px 6px 0 rgba(25, 23, 48, 0.1);
    background-color: white;
    padding: 48px 40px;
    box-sizing: border-box;
    border-radius: 6px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 360px;
        overflow: auto;
        margin-top: 10px;

        &--item {
            align-self: stretch;
            display: flex;
            justify-content: center;

            &>img {
                width: 272px;
            }

            & p {
                font-size: 18px;
                font-weight: normal;
                line-height: 1.78;
                text-align: left;
                color: #040024;
                width: 100%;
                margin: 0;
                margin-top: 37px;
            }
        }
    }
}