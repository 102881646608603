#how_it_works {
    min-height: 464px;
}
.works {
    margin-top: 210px;
    width: 100%;

    & .title{
        padding-top: 100px;
    }

    @media (max-width: 1365px) {
        & .title {
            margin-left: 24px;
            max-width: 360px;
            margin: 0 auto;
        }

        width: calc(100% - 48px);
        margin: 0 auto;
        margin-top: 70px;
    }

    &__cards {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 32px;

        @media (max-width: 1365px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 48px;
        }

        & .card {
            margin-left: 50px;
            background-color: #191730;

            @media (max-width: 1365px) {
                margin-left: 0;
                margin-bottom: 24px;
            }

            &:first-child {
                & img {
                    max-width: 239px;
                    max-height: 187px;
                }

                @media (min-width: 1365px) {
                    margin: 0;
                }
            }

            &:nth-child(2n) {
                & img {
                    max-width: 240.3px;
                    max-height: 203.4px;
                }
            }

            &:last-child {
                & img {
                    max-width: 244px;
                    max-height: 193.3px;
                }
            }

            &__container {
                &--item {
                    & p {
                        color: white;
                    }
                }
            }
        }
    }
}