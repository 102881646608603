body.faq {
    &::before {
        background-image: url('../../../images/bg-elements-faq-desktop.svg');
        background-position: 95% 10%;

    }
        
    & .layout{
        z-index: 2;
    }


    @media (max-width: 767px) {
        &::before {
            background-image: url('../../../images/bg-elements-faq-mobile.svg');
            background-position: 28% -114px;
        }
    }
}

.faq {
    & .layout {
        max-width: 800px;
        margin: 0 auto;
    }

    &__container {
        max-height: auto;

        & .card {
            &__container {
                margin: 0;
                max-height: 100%;
            }

            width: 100%;
            border-radius: 16px;
            max-height: 100%;
            height: auto;
            margin-top: 32px;

            @media (max-width: 767px) {
                position: relative;
                top: 48px;
                margin-top: 0;
                padding: 24px;
            }


            & .questions {
                overflow-x: hidden;
                width: 100%;
                &__list {
                    padding: 0;
                    margin: 0;

                    &--item {
                        text-decoration: none;
                        position: relative;

                        & .faq-dropdown {
                            display: none;
                        }

                        & .faq-dropdown ~ .questions__list--item-answer {
                            display: none;
                        }

                        & .faq-dropdown:checked ~ .questions__list--item-answer{
                            display: block;
                        }

                        & .faq-dropdown:checked ~ .questions__list--item-dropdown {
                            & label {
                                > div{
                                    background: url('../../../images/icons/close-dd-blue.svg');
                                    transform: rotateZ(360deg);
                                    position: relative;
                                }
                            }
                        }

                        & label {
                            display: flex;
                            justify-content: space-between;

                            & input {
                                position: absolute;

                            }

                            &:hover{
                                cursor: pointer;
                            }

                            > div {
                                background: url('../../../images/icons/dd-blue.svg');
                                background-repeat: no-repeat;
                                width: 16px;
                                height: 16px;
                                transition: 0.3s transform;
                            }
                            
                        }
               
                        & span {
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 2.29;
                            color: #0057ff;
                        }

                        &-answer{
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 1.71;
                            color: #191730;

                            @media (max-width: 767px) {
                                font-size: 12px;
                                line-height: 2;
                            }
                        }
                    }
                }
            }
        }
    }
}