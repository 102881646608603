body.privacy {
    &::before {
        background-image: url('../../../images/bg-elements-about-desktop.svg');
        background-position: 50% 0;
    }

    @media (max-width: 767px) {
        &::before{
            background-image: url('../../../images/bg-elements-about-mobile.svg');
            background-position: 28% 0;
        }
    }
}

.privacy {
    & .layout {
        max-width: 800px;
        margin: 0 auto;

    }

    &__container {
        & h1 {
            margin-bottom: 32px;
        }

        & p {
            font-size: 18px;
            line-height: 1.78;
            text-align: left;
            color: #191730;

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
}