.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    flex: 1;
    width: 100%;
    max-width: 100%;
    height: 640px;
    background-color: #040024;
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
    // background: url('../../../images/dark-triangle-bg.svg');
    position: relative;
    bottom: 0;
    z-index: 1;


    @media (max-width: 767px) {
        clip-path: polygon(0 13%, 100% 0, 100% 100%, 0% 100%);
    }

    &__end {
        width: 100%;
        max-width: 896px;

        & hr {
            margin-top: 60px;
            border: 0;
            border-top: 1px solid #6c737f2e;
        }

        & div {
            padding: 13px;
            color: #6c737f;
            font-weight: normal;
            text-align: center;
            font-size: 12px;
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        max-width: 896px;
        width: 100%;
        margin-top: 230px;

        @media (max-width: 1365px) {
            justify-content: space-around;
        }

        @media (max-width: 767px) {
            margin-top: 70px;
            justify-content: flex-start;
            width: 100%;
            flex-wrap: wrap;
        }

        &--list {
            @media (max-width: 767px) {
                flex: 0 0 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &-title {
                color: white;
                font-size: 24px;
                font-weight: normal;
            }

            & ul {
                padding: 0;
                margin: 0;

                & li {
                    list-style: none;
                    min-width: 104px;

                    & h2 {
                        font-size: 24px;
                        margin-bottom: 5px;

                        @media (max-width: 767px) {
                            font-size: 18px;
                            line-height: 2.67;
                        }
                    }

                    & a {
                        text-decoration: none;
                        font-size: 14px;
                        color: white;
                        line-height: 2.29;
                        font-weight: normal;

                        &.active {
                            color: #fea716;
                        }

                        @media (max-width: 767px) {
                            font-size: 12px;
                            line-height: 2.67;
                        }
                    }
                }
            }
        }

        &--icons {
            & img:first-child {
                margin-right: 12px;
            }

            @media (max-width: 767px) {
                margin-top: 30px;
                width: 100%;
                text-align: center;
            }
        }
    }
}