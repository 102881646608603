.countries_list {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    max-height: 535px;

    @media (max-width: 767px) {
        overflow-y: auto;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__country {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        padding: 0 20px;
        display: flex;
        align-items: flex-start;
        margin-top: 24px;

        &-flag {
            margin-right: 7px;
            flex: 1;
            margin-top: 3px;

            & img {
                width: 18px;
                height: 10px;
            }
        }

        &-name {
            font-size: 18px;
            line-height: 1.33;
            text-align: left;
            color: #191730;
            max-width: 180px;

            @media (max-width: 767px) {
                max-width: calc(100%);
            }

        }
    }
}