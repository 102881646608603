html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow-y: overlay;
    overflow-x: hidden;
    font-size: 14px;
    position: relative;
    position: relative;
    min-height: 555px;
    background-color: #f8f7fa;

    &::before {
        content: " ";
        background: unset;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        background: content-box;
        background-position: 78% 0;
        transition: 1.5s all;
    }
}

* {
    transition: 0.8s margin, 1s height, 1s width, 0.5s background-color;

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

#root {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.layout {
    width: 100%;
    min-height: 564px;

    >section {
        max-width: 1170px;
        margin: 0 auto;
        margin-top: 217px;
    }

    >div {
        &:first-child {
            max-width: 1170px;
            margin: 0 auto;
        }
    }

    @media (max-width: 1365px) {
        >section {
            padding: 0 24px;
        }
    }

    @media (max-width: 767px) {
        min-height: 434px;
        padding-bottom: 25px;

        >section {
            margin-top: 108px;
        }
    }
}